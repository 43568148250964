import {
  Alpine,
  Livewire
} from '../../../../vendor/livewire/livewire/dist/livewire.esm.js';

import Collapse from '@alpinejs/collapse';
import Focus from '@alpinejs/focus';
import Intersect from '@alpinejs/intersect';

Alpine.plugin(Collapse);
Alpine.plugin(Intersect);
Alpine.plugin(Focus);

/**
 * Custom Plugins
 */
import emerge from './_emerge';
import announcement from './_announcement';
import { default as header } from './_header';
import resourcesHub from './_resourceHub.js';
import physicianMap from './_physicianMap.js';

Alpine.store('header', header);

/**
 * Reusable Data Objects
 */
import slider from './_slider.js';

Alpine.plugin(emerge);
Alpine.data('slider', slider);
Alpine.data('resourcesHub', resourcesHub);
Alpine.data('physicianMap', physicianMap);
Alpine.data('announcement', () => announcement(document.querySelector('.announcement-banner')?.innerHTML || ''));

Livewire.start();
